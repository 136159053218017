import { Component, ErrorInfo, ReactNode } from "react"
import errorPic from "./assets/sem-conexao.png"
import styled from "styled-components"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ContextoGeral } from "./contextos/ContextoGeral"

const Pre = styled.pre`
	padding: 1rem;
	background: black;
	border-radius: 0.5rem;
	margin: 1rem;
	color: white;
	white-space: pre-wrap;
   white-space: -moz-pre-wrap;
   white-space: -pre-wrap;
   white-space: -o-pre-wrap;
   word-wrap: break-word;  
`

export default class Boundary extends Component {
	static contextType = ContextoGeral

	constructor(props) {
		super(props)
		this.state = { hasError: false, text: "", stack: null }
	}

	static getDerivedStateFromError(e) {
		return { hasError: true, text: e.message, stack: e?.stack }
	}

	async componentDidCatch(error, errorInfo) {
		const { dados } = this.context
		await axios.post(`https://${dados.dominio}/md/mdLumen/lumen/appProf/reportarErro`, {
			dominio: dados.dominio,
			idProf: dados.idProf,
			texto: this.state.text ?? "Erro desconhecido",
			stacktrace: `Página ${window.location.pathname}\n` + this.state.stack
		})

		console.error("Uncaught error:", error, errorInfo)
	}

	render() {
		if (this.state.hasError)
			return <div style={{
				height: "calc(100vh - 2rem)",
				width: window.innerWidth > window.innerHeight
					? "512px"
					: "100%",
			}}>
				<img src={errorPic} style={{
					width: "100vw",
					marginTop: "2rem",
					objectFit: "cover"
				}} />
				<h1 className="text-center text-danger lh-1">Desculpe, houve um problema neste aplicativo!</h1>
				<Pre>
					{this.state.text}
				</Pre>
				<ContextoGeral.Consumer>
					{({ dados }) => <Button onClick={async () => {
						window.location.href = "/?dominio=" + dados.dominio
					}} fullWidth>
						Recarregar
					</Button>}
				</ContextoGeral.Consumer>
			</div>
		return this.props.children
	}
}