import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { configAnim } from "../../../servicos/config/configAnim";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import MenuLateral from "../../../componentes/gerais/Menu";
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import DimencaoIconesDias from "./dimencaoIconesDias";
import { useLocation, Link, useNavigate } from "react-router-dom";
import './style.css';
import APISemNode from "../../../servicos/APISemNode";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { Spinner } from "../../../componentes/Spinner"
import { MdSave } from "react-icons/md";

export default function DetalhesHorarios() {
    const { dados } = useContext(ContextoGeral);
    const [menu, setMenu] = useState(false);
    const { state } = useLocation();
    const [dadosHorario, setDadosHorario] = useState([]);
    const [mensagem, setMensagem] = useState();
    const [loading, setLoading] = useState(true);
    const [validado, setValidado] = useState();
    const [mostrarModal, setMostrarModal] = useState();
    const [mostrarMensagem, setMostrarMensagem] = useState(false);
    const [gifResposta, setGifResposta] = useState(false);
    const [corAlert, setCorAlert] = useState(false);
    const [mensagemResposta, setMensagemResposta] = useState(false);
    const [origem, setOrigem] = useState();
    const [cores, setCores] = useState({
        segunda: { bg: 'white', color: 'black' },
        terca: { bg: 'white', color: 'black' },
        quarta: { bg: 'white', color: 'black' },
        quinta: { bg: 'white', color: 'black' },
        sexta: { bg: 'white', color: 'black' },
        sabado: { bg: 'white', color: 'black' },
        domingo: { bg: 'white', color: 'black' }
    });
    const [profissionalDiasInscrito, setProfissionalDiasInscrito] = useState(state?.profissionalDiasInscrito);

    const [avatarWidth, avatarHeight] = DimencaoIconesDias();

    const navegar = useNavigate()

    function navegacao(url) {
        navegar(url, {
            state: {}
        })
    }

    async function buscarDetalhes(idHorario) {

        const pNomeUrl = dados.dominio.split(".")[0];

        await APISemNode.requisicaoGET(`https://${dados.dominio}`, `id=${idHorario}&idProf=${dados.idProf}&pNomeUrl=${pNomeUrl}&token=${dados.token}`, 'md/mdLumen/lumen/HorarioProfissional/listarConfigs', "").then(res => {

            if (res?.codigo == 200) {
                setDadosHorario(res?.dados);
                setValidado(true)
            } else {
                setMensagem('Houve um problema ao buscar configurações de horário')
                setValidado(false)
            }
        }).catch(rej => {
            setValidado(false)
        })

        setLoading(false);
    }

    function inscreverProf() {
        setMostrarModal(true);
        diasInscritos()
    }

    function fecharModalClick() {
        setMostrarModal(false)
        setMostrarMensagem(false)
    }

    const selecionarDiaSemana = (dia) => {
        setCores((prevCores) => ({
            ...prevCores,
            [dia]: {
                bg: prevCores[dia].bg === 'white' ? 'green' : 'white',
                color: prevCores[dia].color === 'black' ? 'white' : 'black'
            }
        }));
    };

    async function salvarDias() {

        setGifResposta(true)

        const obj = {
            idConfig: dadosHorario.id,
            idProf: dados.idProf,
            pNomeUrl: dados.dominio.match(/^(.*?)\./)[1],
            tipoVeiculo: dados.tipoVeiculo,
            token: dados.token,
            segunda: '',
            terca: '',
            quarta: '',
            quinta: '',
            sexta: '',
            sabado: '',
            domingo: '',
        }

        Object.entries(cores).map((dia) => {
            return obj[dia[0]] = dia[1]?.bg === "green" ? 'S' : 'N'
        });

        await APISemNode.requisicaoPUT(`https://${dados.dominio}`, obj, 'md/mdLumen/lumen/HorarioProfissional/salvarDiaInscreveu', '').then(res => {
            setMostrarMensagem(true)
            if (res.codigo === 200) {
                setMensagemResposta('Salvo com sucesso.')
                setCorAlert('success')
                setGifResposta(false)
                buscarDetalhes(dadosHorario.id)
                setProfissionalDiasInscrito({
                    seg: obj?.segunda,
                    ter: obj?.terca,
                    qua: obj?.quarta,
                    qui: obj?.quinta,
                    sex: obj?.sexta,
                    sab: obj?.sabado,
                    dom: obj?.domingo,
                }); console.log(obj);
            } else {
                setMensagemResposta('Houve um problema ao salvar, por favor tente novamente.')
                setCorAlert('error')
                setGifResposta(false)
            }
        })
    }

    async function diasInscritos() {

        const pNomeUrl = dados?.dominio.match(/^(.*?)\./)[1];

        await APISemNode.requisicaoGET(`https://${dados.dominio}`, `token=${dados?.token}&pNomeUrl=${pNomeUrl}&idProf=${dados?.idProf}&idConfig=${dadosHorario?.id}`, `md/mdLumen/lumen/HorarioProfissional/horarioProfissional`, dados?.token).then(res => {

            if (res.codigo === 200) {

                setCores({
                    segunda: { bg: res?.dados?.seg === 'S' ? 'green' : 'white', color: res?.dados?.seg === 'S' ? 'white' : 'black' },
                    terca: { bg: res?.dados?.ter === 'S' ? 'green' : 'white', color: res?.dados?.ter === 'S' ? 'white' : 'black' },
                    quarta: { bg: res?.dados?.qua === 'S' ? 'green' : 'white', color: res?.dados?.qua === 'S' ? 'white' : 'black' },
                    quinta: { bg: res?.dados?.qui === 'S' ? 'green' : 'white', color: res?.dados?.qui === 'S' ? 'white' : 'black' },
                    sexta: { bg: res?.dados?.sex === 'S' ? 'green' : 'white', color: res?.dados?.sex === 'S' ? 'white' : 'black' },
                    sabado: { bg: res?.dados?.sab === 'S' ? 'green' : 'white', color: res?.dados?.sab === 'S' ? 'white' : 'black' },
                    domingo: { bg: res?.dados?.dom === 'S' ? 'green' : 'white', color: res?.dados?.dom === 'S' ? 'white' : 'black' }
                });
            }
        })
    }


    useEffect(() => {
        if (state?.horarioid > 0)
            buscarDetalhes(state?.horarioid);

        if (state?.origem === "HD") setOrigem('/app/horariosDisponiveis');
        else setOrigem('/app/horariosDisponiveis/horariosInscritos');
    }, [])

    return <motion.div
        style={{ height: "100vh" }}
        initial={configAnim.initial}
        animate={configAnim.animate}
        exit={configAnim.exit}
        transition={configAnim.transition}
        layout
    >
        <TopoMenu voltar={origem} titulo="Detalhes do horário" setShow={setMenu} state={state?.cores}></TopoMenu>
        <MenuLateral show={menu} setShow={setMenu} />

        {loading
            ? <div className="mt-4 text-center">
                <Spinner />
            </div>
            : validado
                ? <div className="mx-3 mt-4">

                    <div className="row mb-3">
                        <div className="col-md-12 text-center">
                            <h3>{dadosHorario?.nome}</h3>
                        </div>
                    </div>
                    
                    <Stack direction={"row"} spacing={2} marginTop={2}>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.seg == "S" && dadosHorario?.podeCadastrarSeg
                                ? profissionalDiasInscrito?.seg === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.seg === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Seg</Avatar>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.ter == "S" && dadosHorario?.podeCadastrarTer
                                ? profissionalDiasInscrito?.ter == "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.ter === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Ter</Avatar>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.quart == "S" && dadosHorario?.podeCadastrarQua
                                ? profissionalDiasInscrito?.qua === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.qua === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Qua</Avatar>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.quint == "S" && dadosHorario?.podeCadastrarQui
                                ? profissionalDiasInscrito?.qui === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.qui === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Qui</Avatar>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.sex == "S" && dadosHorario?.podeCadastrarSex
                                ? profissionalDiasInscrito?.sex === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.sex === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Sex</Avatar>

                        <Avatar sx={{
                            bgcolor: dadosHorario?.sab == "S" && dadosHorario?.podeCadastrarSab
                                ? profissionalDiasInscrito?.sab === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.sab === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Sab</Avatar>
                        <Avatar sx={{
                            bgcolor: dadosHorario?.dom == "S" && dadosHorario?.podeCadastrarDom
                                ? profissionalDiasInscrito?.dom === "S" ? "green" : "white"
                                : "red",
                            color: profissionalDiasInscrito?.dom === "S" ? "white" : "black",
                            border: "1px solid black",
                            width: avatarWidth, height: avatarHeight, fontSize: 10
                        }}>Dom</Avatar>
                    </Stack>
                    <Stack direction={"row"} spacing={2} marginTop={1}>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.seg === "S" && dadosHorario?.podeCadastrarSeg
                                ?
                                <>
                                    {dadosHorario?.segHi > "00:00:00" &&
                                        <span>{dadosHorario?.segHi.split(":").map(Number)[0]} : {dadosHorario?.segHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.segHf > "00:00:00" &&
                                        <span>{dadosHorario?.segHf.split(":").map(Number)[0]} : {dadosHorario?.segHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.ter === "S" && dadosHorario?.podeCadastrarTer
                                ?
                                <>
                                    {dadosHorario?.terHi > "00:00:00" &&
                                        <span>{dadosHorario?.terHi.split(":").map(Number)[0]} : {dadosHorario?.terHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.terHf > "00:00:00" &&
                                        <span>{dadosHorario?.terHf.split(":").map(Number)[0]} : {dadosHorario?.terHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quart === "S" && dadosHorario?.podeCadastrarQua
                                ?
                                <>
                                    {dadosHorario?.quarHi > "00:00:00" &&
                                        <span>{dadosHorario?.quarHi.split(":").map(Number)[0]} : {dadosHorario?.quarHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.quarHf > "00:00:00" &&
                                        <span>{dadosHorario?.quarHf.split(":").map(Number)[0]} : {dadosHorario?.quarHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quint === "S" && dadosHorario?.podeCadastrarQui
                                ?
                                <>
                                    {dadosHorario?.quintHi > "00:00:00" &&
                                        <span>{dadosHorario?.quintHi.split(":").map(Number)[0]} : {dadosHorario?.quintHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.quintHf > "00:00:00" &&
                                        <span>{dadosHorario?.quintHf.split(":").map(Number)[0]} : {dadosHorario?.quintHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sex === "S" && dadosHorario?.podeCadastrarSex
                                ?
                                <>
                                    {dadosHorario?.sexHi > "00:00:00" &&
                                        <span>{dadosHorario?.sexHi.split(":").map(Number)[0]} : {dadosHorario?.sexHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.sexHf > "00:00:00" &&
                                        <span>{dadosHorario?.sexHf.split(":").map(Number)[0]} : {dadosHorario?.sexHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sab === "S" && dadosHorario?.podeCadastrarSab
                                ?
                                <>
                                    {dadosHorario?.sabHi > "00:00:00" &&
                                        <span>{dadosHorario?.sabHi.split(":").map(Number)[0]} : {dadosHorario?.sabHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.sabHf > "00:00:00" &&
                                        <span>{dadosHorario?.sabHf.split(":").map(Number)[0]} : {dadosHorario?.sabHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                        <span style={{ fontSize: "11px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.dom === "S" && dadosHorario?.podeCadastrarDom
                                ?
                                <>
                                    {dadosHorario?.domHi > "00:00:00" &&
                                        <span>{dadosHorario?.domHi.split(":").map(Number)[0]} : {dadosHorario?.domHi.split(":").map(Number)[1]}</span>
                                    }
                                    <span className="quebrarLinha"> - </span>
                                    {dadosHorario?.domHf > "00:00:00" &&
                                        <span>{dadosHorario?.domHf.split(":").map(Number)[0]} : {dadosHorario?.domHf.split(":").map(Number)[1]}</span>
                                    }
                                </>
                                : <></>}
                        </span>
                    </Stack>

                    <div className="row mb-1 mt-4">
                        <div className="col-md-12 text-center">
                            <h6>Limite para cadastrar profissionais</h6>
                        </div>
                    </div>
                    <Stack direction={"row"} spacing={2} marginTop={2}>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Seg</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Ter</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qua</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qui</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sex</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sab</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Dom</Avatar>
                    </Stack>

                    <Stack direction={"row"} spacing={2} marginTop={1}>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.segQP > 0 ? dadosHorario.segQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.terQP > 0 ? dadosHorario.terQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quartQP > 0 ? dadosHorario.quartQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quintQP > 0 ? dadosHorario.quintQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sexQP > 0 ? dadosHorario.sexQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sabQP > 0 ? dadosHorario.sabQP : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.domQP > 0 ? dadosHorario.domQP : 'Sem limite'}
                        </span>
                    </Stack>

                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h6>Limite de profissionais que podem ficar online</h6>
                        </div>
                    </div>
                    <Stack direction={"row"} spacing={2} marginTop={2}>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Seg</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Ter</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qua</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Qui</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sex</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Sab</Avatar>
                        <Avatar sx={{ bgcolor: "white", color: "black", border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 10 }}>Dom</Avatar>
                    </Stack>

                    <Stack direction={"row"} spacing={2} marginTop={1}>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.segQPO > 0 ? dadosHorario.segQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.terQPO > 0 ? dadosHorario.terQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quarQPO > 0 ? dadosHorario.quarQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.quintQPO > 0 ? dadosHorario.quintQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sexQPO > 0 ? dadosHorario.sexQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.sabQPO > 0 ? dadosHorario.sabQPO : 'Sem limite'}
                        </span>
                        <span style={{ fontSize: "13px", width: "22%", textAlign: "center" }}>
                            {dadosHorario?.domQPO > 0 ? dadosHorario.domQPO : 'Sem limite'}
                        </span>
                    </Stack>

                    <div className="row my-3">
                        <div className="col-md-12">
                            {dadosHorario?.formaConfigurar === "VF" ? <span>Valor fixo</span> : ''}
                            {dadosHorario?.formaConfigurar === "VB" ? <span>Valor base</span> : ''}
                            {dadosHorario?.formaConfigurar === "VG" ? <span>Valor geral</span> : ''}
                            {dadosHorario?.valor > 0 ? <span> R${dadosHorario?.valor.replace(/\./g, ",")}</span> : ""}
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-md-12">
                            <button
                                className="btnFullW my-1"
                                onClick={e => inscreverProf()}
                            >
                                Inscrever-se
                            </button>
                        </div>
                    </div>

                </div>
                : <Alert security="error" className="mx-3 mt-4 text-center">
                    {mensagem ? mensagem : 'Houve um problema ao buscar detalhes dessa configuração de horário'}
                </ Alert>
        }

        {
            mostrarModal
                ? <Dialog open={mostrarModal} onClose={e => fecharModalClick()}>
                    <DialogTitle>Selecione os dias que deseja trabalhar</DialogTitle>
                    <DialogContent>
                        <Stack direction={"row"} spacing={1.7} marginTop={2} marginLeft={-2} textAlign={"center"}>
                            {dadosHorario?.seg == "S" && dadosHorario?.podeCadastrarSeg
                                ? <Avatar sx={{ bgcolor: cores.segunda.bg, color: cores.segunda.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('segunda')}>Seg</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Seg</Avatar>
                            }
                            {dadosHorario?.ter == "S" && dadosHorario?.podeCadastrarTer
                                ? <Avatar sx={{ bgcolor: cores.terca.bg, color: cores.terca.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('terca')}>Ter</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Ter</Avatar>
                            }
                            {dadosHorario?.quart == "S" && dadosHorario?.podeCadastrarQua
                                ? <Avatar sx={{ bgcolor: cores.quarta.bg, color: cores.quarta.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('quarta')}>Qua</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Qua</Avatar>
                            }
                            {dadosHorario?.quint == "S" && dadosHorario?.podeCadastrarQui
                                ? <Avatar sx={{ bgcolor: cores.quinta.bg, color: cores.quinta.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('quinta')}>Qui</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Qui</Avatar>
                            }
                            {dadosHorario?.sex == "S" && dadosHorario?.podeCadastrarSex
                                ? <Avatar sx={{ bgcolor: cores.sexta.bg, color: cores.sexta.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('sexta')}>Sex</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Sex</Avatar>
                            }
                            {dadosHorario?.sab == "S" && dadosHorario?.podeCadastrarSab
                                ? <Avatar sx={{ bgcolor: cores.sabado.bg, color: cores.sabado.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('sabado')}>Sab</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Sab</Avatar>
                            }
                            {dadosHorario?.dom == "S" && dadosHorario?.podeCadastrarDom
                                ? <Avatar sx={{ bgcolor: cores.domingo.bg, color: cores.domingo.color, border: "1px solid black", width: avatarWidth, height: avatarHeight, fontSize: 12 }} onClick={e => selecionarDiaSemana('domingo')}>Dom</Avatar>
                                : <Avatar sx={{ bgcolor: "red", width: avatarWidth, height: avatarHeight, fontSize: 12 }}>Dom</Avatar>
                            }
                        </Stack>

                        <div className="row mt-4">
                            <div className="col-md-12 text-center">
                                <button
                                    className="btnFullW my-1"
                                    onClick={e => salvarDias()}
                                >
                                    <span className="me-2">Salvar</span>
                                    <MdSave />
                                </button>
                            </div>
                        </div>

                        {gifResposta
                            ? <div className="mt-4 text-center">
                                <Spinner />
                            </div>
                            : mostrarMensagem
                                ? <div className="row">
                                    <div className="col-md-12 text-center">
                                        <Alert security={corAlert} className="mx-3 mt-4 text-center" onClick={e => navegacao('/app/horariosDisponiveis/horariosInscritos')}>
                                            {mensagemResposta} {corAlert === 'success' ? 'Clique aqui para ver os horarios inscritos.' : ''}
                                        </Alert>
                                    </div>
                                </div>
                                : <></>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => fecharModalClick()} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                : ''
        }
    </motion.div >
}