import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { configAnim } from "../../../servicos/config/configAnim";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import MenuLateral from "../../../componentes/gerais/Menu";

import FiltroDiasSemana from "./filtroDiasSemana";
import { useLocation } from "react-router-dom";

export default function horariosInscritos() {
    const [menu, setMenu] = useState(false)
    const { state } = useLocation();

    const [cores, setCores] = useState({
        Seg: 'green',
        Ter: 'green',
        Qua: 'green',
        Qui: 'green',
        Sex: 'green',
        Sab: 'green',
        Dom: 'green'
    });

    useEffect(() => {
        if (state?.cores) {
            setCores({
                Seg: state?.cores?.Seg,
                Ter: state?.cores?.Ter,
                Qua: state?.cores?.Qua,
                Qui: state?.cores?.Qui,
                Sex: state?.cores?.Sex,
                Sab: state?.cores?.Sab,
                Dom: state?.cores?.Dom
            })
        } else {
            setCores({
                Seg: state?.Seg,
                Ter: state?.Ter,
                Qua: state?.Qua,
                Qui: state?.Qui,
                Sex: state?.Sex,
                Sab: state?.Sab,
                Dom: state?.Dom
            })
        }
    }, [])
    
    return <motion.div
        style={{ height: "100vh" }}
        initial={configAnim.initial}
        animate={configAnim.animate}
        exit={configAnim.exit}
        transition={configAnim.transition}
        layoout
    >
        <TopoMenu voltar="/App/horariosDisponiveis" titulo="Horários inscritos" setShow={setMenu} state={cores}></TopoMenu>
        <MenuLateral show={menu} setShow={setMenu} />

        <div className="mx-3 mt-4">
            <FiltroDiasSemana origemHorario={"HI"} cores={cores} setCores={setCores} />
        </div>

    </motion.div>
}