import { createContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import MensagemApp from '../servicos/config/MensagemApp'
import ProfissionalAPI from '../servicos/ProfissionalAPI'
import { IconContext } from 'react-icons/lib'
import APISemNode from "./../servicos/APISemNode";
// import useNomeSala from "./../paginas/App/chat/hook/useNomeSala";
// import useSalvarSala from "./../paginas/App/chat/hook/useSalvarSala";

// import { io } from 'socket.io-client';

export const ContextoGeral = createContext({})
ContextoGeral.displayName = 'contexto geral'

const theme = createTheme({
	palette: {
		action: {
			disabledBackground: "grey",
			disabled: "black"
		},
		text: {
			primary: '#000000'
		},
		primary: {
			main: '#000000',
			contrastText: '#fff'
		}
	}
})

export function ContextoGeralProvider({ children }) {
	const [dados, setDados] = useState({})
	// const [websocket, setWebsocket] = useState(null) // Estado para armazenar a conexão WebSocket

	const navegar = useNavigate()

	location.state = {}
	location.state.conversa = 'central';

	async function deslogar() {
		let host = ""

		let retorno = await ProfissionalAPI.alterarStatus('OF', dados)

		if (retorno?.resultado) {
			if (!!window.AndroidInterface) {
				if (typeof window.AndroidInterface.stopBackgroundLocation === "function") {
					host = window.AndroidInterface.recoverdomain()
					window.AndroidInterface.saveToken('')
					window.AndroidInterface.stopBackgroundLocation()
					window.AndroidInterface.showAndroidVersion(MensagemApp.deslogado)
				}

			} else alert(MensagemApp.deslogado)

			setDados({})
			navegar('/?dominio=' + host)
		} else {
			if (retorno?.resultado) {
				if (typeof window.AndroidInterface !== 'undefined') {
					if (typeof window.AndroidInterface.showAndroidVersion === "function") {

						if (retorno?.mensagem) {
							window.AndroidInterface.showAndroidVersion(retorno.mensagem)
						}
					}

					navegar('/app/home')

				} else try {
					window.AndroidInterface.showAndroidVersion(retorno.mensagem)
				} catch (err) {
					alert(err)
				}
			} else try {
				window.AndroidInterface.showAndroidVersion(retorno.mensagem)
			} catch (err) {
				alert(err)
			}
		}
	}

	async function buscarIdConfig(dominio) {

		if (!dados?.idConfig) {
			APISemNode.requisicaoGET(
				`https://${dominio}`,
				`dominio=${dominio}`,
				'sem/v1/rotas.php/modulo-chat-profissional/buscarIdConfig',
				dados?.token
			)
				.then(response => {

					if (response?.status == 200) {
						const idConfig = response.dados;
						setDados((prevDados) => ({ ...prevDados, idConfig }));
					} else {
						console.error("houve um problema ao buscar idconfig");
					}
				})
				.catch(error => {
					console.error("Erro ao buscar idConfig");
				});
		}
	}

	useEffect(() => {
		if (dados != undefined) {

			if (window.location.pathname == "/relatar")
				return
			if (!!dados?.config?.corPrincipal) {
				theme.palette.primary.main = dados.config.corPrincipal
				theme.palette.text.primary = dados.config.corPrincipal
			}

			if (Object.keys(dados).length === 0) {
				if (!!window.AndroidInterface) {
					if (typeof window.AndroidInterface.recoverToken === "function") {

						let token = window.AndroidInterface.recoverToken()

						if (token !== '') {
							if (typeof window.AndroidInterface.recoverdomain === "function") {
								let host = window.AndroidInterface.recoverdomain()
								if (host != '') {
									let rota = `/?dominio=${host}`
									navegar(rota)
								}
							}
						}
					}
				} else {
					navegar('/')
				}
			} else if (dados.dominio === undefined) {
				navegar('/')
			}
		} else {
			navegar('/')
		}

		if (Object.keys(dados).length > 0) { // Verifica se os dados estão prontos

			buscarIdConfig(dados.dominio);
		}

	}, [dados])

	const [searchParams, setSearchParams] = useSearchParams()

	if (dados == {}) {
		if (!!window.AndroidInterface) {
			if (typeof window.AndroidInterface.recoverdomain === "function") {
				setDados(dados => ({
					...dados,
					dominio: window.AndroidInterface.recoverdomain()
				}))
			}
		}
	}

	return (
		<ContextoGeral.Provider value={{ dados, setDados, deslogar }}>
			<IconContext.Provider value={{ style: { verticalAlign: 'center' } }}>
				<ThemeProvider theme={theme}>
					{/* {Object.keys(dados).length > 0 && (
						<ChatManager dados={dados} setDados={setDados} setWebsocket={setWebsocket} />
					)} */}
					{children}
				</ThemeProvider>
			</IconContext.Provider>
		</ContextoGeral.Provider>
	)
}

// function ChatManager({ dados, setDados, setWebsocket }) {
// 	const websocketRef = useRef(null); // Armazena a instância do WebSocket
// 	const isInitialized = useRef(false); // Garante que o WebSocket seja inicializado apenas uma vez

// 	location.state = {};
// 	location.state.paraQuem = 'central';

// 	const { quemConversar, nomeSala, voltar } = useNomeSala(location, dados, true);
// 	const { idSala } = useSalvarSala({ ...dados, room: nomeSala, paraQuem: quemConversar }, location, true);


// 	useEffect(() => {

// 		if (!dados?.idSala && !dados?.quemConversar) {
// 			setDados((prevDados) => ({ ...prevDados, idSala, nomeSala, quemConversar }));
// 		}
// 	}, [idSala]);

// 	useEffect(() => {

// 		// Verifica se os dados estão prontos e se o WebSocket não foi inicializado
// 		if (quemConversar && nomeSala) {


// 			if (dados?.idProf && !isInitialized.current) {
// 				isInitialized.current = true; // Marca que o efeito foi executado uma vez

// 				// const nodeUrl = `ws://localhost:8081`;
// 				const nodeUrl = `https://chatmensagem.${dados.dominio}`

// 				// Inicializa o WebSocket
// 				const ws = io(nodeUrl, {
// 					query: {
// 						json: JSON.stringify({
// 							id: dados.idProf,
// 							name: dados.nome,
// 							room: nomeSala,
// 							idConfig: dados.idConfig,
// 							target: quemConversar,
// 							tipoUsuario: 'prof',
// 						}),
// 						identifier: 'prof',
// 					},
// 					transports: ['websocket'],
					
// 				}); // Certifique-se de que a URL está correta

// 				websocketRef.current = ws; // Armazena a instância do WebSocket

// 				setWebsocket(ws);

// 				// Fecha a conexão ao desmontar
// 				return () => {
// 					ws.on('disconnect', () => {
// 						console.log('Desconectado do servidor Socket.IO');
// 					});
// 				};
// 			}
// 		}
// 	}, [quemConversar, nomeSala]);

// 	return null; // O componente não precisa renderizar nada visível
// }
