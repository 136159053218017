import { Card, Chip } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { MdAttachMoney, MdCircle, MdMonetizationOn, MdPanoramaFishEye, MdUndo } from "react-icons/md";
import Status from "../uteis/Status";

import { format, parse } from "date-fns"
import styled from "styled-components";
import { useEffect, useState } from "react";

import icon from "./../../assets/icones/relogio.svg"

export const Marcador = styled.div`
	height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: safe center;
	font-size: 1rem;
	font-weight: 700;
	margin: 0.15rem 0.25rem 0.25rem 0;
`

export default function CardServico({ servico, setModal, obj, mostrarNomes, hideValue, action, corDinamica }) {
	const urgente = servico["s.urgente"] == "S"
	const economico = servico["s.freteExpresso"] != ""

	return <Card className={`p-3 bgGradientGrey ${urgente && 'text-danger'}`}>
		{urgente && <div className="bg-danger mb-2 text-light" style={{
			margin: "-1rem",
			padding: "0.5rem 1rem"
		}}>
			<div className="d-flex align-items-center gap-2">
				<img src={icon} style={{
					height: "1.25rem",
					filter: "brightness(10)"
				}} alt="" />
				Serviço urgente
			</div>
		</div>}

		{economico && <div className="bg-info mb-2 text-light" style={{
			margin: "-1rem",
			padding: "0.5rem 1rem"
		}}>
			<div className="d-flex align-items-center gap-2">
				<MdMonetizationOn size={24} />
				Serviço econômico
			</div>
		</div>}

		<Row className="mb-2">
			<Col xs={7} className="d-flex align-items-center">
				<h4 style={{ fontWeight: 800 }} className="me-2 mb-0">
					#{servico['s.id']}
				</h4>
				<div>
					{servico['s.ifood'] && <Chip color="error" label="iFood" size="small" />}
					{servico['s.dataHoraColetar'] && servico['s.dataHoraColetar'] != '0000-00-00 00:00:00' && <Chip color="warning" label="Agendado" size="small" />}
				</div>
			</Col>
			<Col xs={5} className="d-flex justify-content-end">
				<Status texto={servico?.filtroLegenda ?? 'CONCLUIDO'} danger={urgente} />
			</Col>
		</Row>

		{servico["nome"] && <Row className="mb-2">
			<Col className="d-inline-block text-truncate small" style={{
				color: 'var(--primaria-darkest)'
			}}>
				<Chip label={<><b>Solicitante:</b> {servico["nome"]}</>} />

			</Col>
		</Row>}

		<div onClick={() => {
			if (setModal && obj) setModal(obj)
		}}>
			{servico.enderecos
				? <Row>
					<Col className="d-inline-block text-truncate" style={{
						color: 'var(--primaria-darkest)'
					}}>
						{servico.enderecos[0]['e.rua']}
					</Col>
				</Row>
				: <div className="text-center">
					Sem endereços
				</div>}

			<Row>
				{servico.enderecos?.length == 2
					? <Col className="d-inline-block text-truncate my-1" style={{
						color: 'var(--primaria-darkest)'
					}}>
						{servico.enderecos[1]['e.rua']}
					</Col>
					: <><Col xs="6" className="d-flex align-items-center opacity-50 text-truncate" style={{
						color: 'var(--primaria-darkest)'
					}}>
						<div className="small d-flex gap-2 align-items-center">
							{(servico.enderecos?.length - 1) > 1
								? <>
									<Marcador>
										+{servico.enderecos.length - 1}
									</Marcador>
									<div>
										Endereços
									</div>
								</> : ''}
						</div>
					</Col>
						<Col
							xs="6"
							className="d-flex align-items-center justify-content-end opacity-50"
						>
							{servico['s.retorno'] == 'S' && <div
								style={{ color: 'var(--primaria-darkest)' }}
								className="d-flex align-items-center gap-1 small">
								<MdUndo size="22" />
								Retorno ao 1º ponto
							</div>}
						</Col>
					</>
				}
			</Row>
		</div>

		{servico.tempoColetarSegundosFalta && <TimerColeta label={servico.tempoColetarMsg} tempoInicial={servico.tempoColetarSegundosFalta} tempoMax={servico.tempoMax} />}

		{!hideValue && <Row className="g-1 mt-2">
			{(servico['s.valorProfissional'] || servico['s.valor']) && <Col>
				<Row className="pe-0 m-0">
					<Status
						danger={urgente}
						texto={new Intl.NumberFormat('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						}).format(servico['s.valorProfissional'] ?? servico['s.valor'])}
						fill={true}
					/>
				</Row>
			</Col>}

			{servico['s.distancia'] != '' && servico['s.distancia'] != null && servico["s.distancia"] != "-" ? (
				<Col>
					<Row className="m-0 px-0">
						<Status
							danger={urgente}
							texto={
								(servico['s.distancia'] !== null || servico['s.distancia'] !== undefined)
									? (servico['s.distancia'].replace('.', ',') + 'km')
									: ('')
							}
							fill={true}
						/>
					</Row>
				</Col>
			) : ""}

			{servico['s.dataHoraColetar'] && servico['s.dataHoraColetar'] != null
				? <Col xs={12} sm={4}>
					<small>
						<Row className="ps-0 m-0">
							<Status
								danger={urgente}
								texto={servico['s.dataHoraColetar'] === '0000-00-00 00:00:00'
									? 'AGORA'
									: Date.parse(servico['s.dataHoraColetar']) != NaN
										? format(new Date(servico['s.dataHoraColetar'].replace(/^0+/, '')), 'dd/MM HH:mm')
										: <small>{Date(servico['s.dataHoraColetar'])}</small>}
								fill={true}
								color={servico['s.dataHoraColetar'] != '0000-00-00 00:00:00' ? '#ed6c02' : null}
							/>
						</Row>
					</small>
				</Col>
				: <>
					{servico['s.horaColetado'] && servico['s.horaColetado'] != null
						? <Col xs sm={4}>
							<small>
								<Row className="ps-0 m-0">
									<Status
										danger={urgente}
										texto={new Date(servico['s.horaColetado']).toLocaleDateString()}
										fill={true}
									/>
								</Row>
							</small>
						</Col>
						: ''}
				</>
			}

			{servico['data']
				? <Col>
					<small>
						<Row className="ps-0 m-0">
							<Status
								danger={urgente}
								texto={new Date(servico['data']).toLocaleDateString()}
								fill={true}
							/>
						</Row>
					</small>
				</Col>
				: servico['s.dataFinalizacao'] && servico['s.dataFinalizacao'] != null
					? <Col>
						<small>
							<Row className="ps-0 m-0">
								<Status
									danger={urgente}
									texto={new Date(servico['s.dataFinalizacao']).toLocaleDateString()}
									fill={true}
								/>
							</Row>
						</small>
					</Col>
					: ''}
		</Row>}

		{mostrarNomes == true && <div className="mt-0 status-grid" style={{
			display: "grid",
			paddingTop: "5px",
			gridTemplateColumns: "repeat(auto-fit, minmax(30%, 1fr))",
			gap: "5px"
		}}>
			{servico["so.nome"] && servico["so.nome"] != "-" && <div className="text-truncate grid-item">
				<Status
					danger={urgente}
					texto={servico["so.nome"]}
					fill={true}
					color={corDinamica && servico["so.corCliente"]}
					truncate
					black={corDinamica}
					pop
				/>
			</div>}

			{servico["so.empresa"] && servico["so.empresa"] != "-" && <div className="grid-item">
				<Status
					danger={urgente}
					texto={servico["so.empresa"]}
					fill={true}
					color={corDinamica && servico["so.corCliente"]}
					truncate
					black={corDinamica}
					pop
				/>
			</div>}

			{servico["so.nomeFantasia"] && servico["so.nomeFantasia"] != "-" && <div className="text-truncate grid-item">
				<Status
					danger={urgente}
					texto={servico["so.nomeFantasia"]}
					fill={true}
					color={corDinamica && servico["so.corCliente"]}
					truncate
					black={corDinamica}
					pop
				/>
			</div>}

		</div>}
		{servico["sa.direcao"] && servico["sa.direcao"] != "-" && <div className="mt-1">
			<Status
				danger={urgente}
				texto={servico["sa.direcao"]}
				fill={true}
				color={corDinamica && servico["so.corCliente"]}
				truncate
				black={corDinamica}
				pop
			/>
		</div>}
		{action && action}
	</Card >
}

export function TimerColeta({ label, tempoInicial, tempoMax }) {
	const [tempo, setTempo] = useState(tempoInicial)

	useEffect(() => {
		if (tempo > 0) setTimeout(() => setTempo(p => p - 1), 1000)
	}, [tempo])

	const formatTime = (totalSeconds) => {
		const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0')
		const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0')
		const seconds = String(totalSeconds % 60).padStart(2, '0')
		return `${hours}:${minutes}:${seconds}`
	}

	return <div style={{
		animation: "pulse 1s infinite",
		fontWeight: 800
	}}>
		<div style={{
			display: "flex",
			justifyContent: "right",
			borderRadius: "8px",
			boxShadow: "inset 0 0 6px -3px #0003"
		}}>
			<div style={{
				width: `${(tempo / tempoMax) * 100}%`,
				height: "12px",
				background: "var(--primaria)",
				borderRadius: "8px"
			}} />
		</div>
		<div className="d-flex justify-content-between">
			<div>{label}</div>
			<div>{formatTime(tempo)}</div>
		</div>
	</div>
}