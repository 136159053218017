import { Alert, Avatar, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import DimencaoIconesDias from "./dimencaoIconesDias";
import APISemNode from "./../../../servicos/APISemNode";
import { Spinner } from "../../../componentes/Spinner";
import CardsHorarios from "./cardHorarios";

export default function FiltroDiasSemana({ origemHorario, cores, setCores }) {
    const { dados } = useContext(ContextoGeral);

    const [avatarWidth, avatarHeight] = DimencaoIconesDias();
    const [respostaRequisicao, setRespostaRequisicao] = useState([]);
    const [loading, setLoading] = useState(true);
    const [validado, setValidado] = useState(false)


    let filtros = {
        segunda: '',
        terca: '',
        quarta: '',
        quinta: '',
        sexta: '',
        sabado: '',
        domingo: '',
    }
    const handleFilter = (dia) => {

        setCores((prevCores) => ({
            ...prevCores,
            [dia]: prevCores[dia] === 'green' ? 'red' : 'green'
        }));
    }

    useEffect(() => {

        if (cores.Seg === 'green') filtros.segunda = 'S';
        else filtros.segunda = 'N';

        if (cores.Ter === 'green') filtros.terca = 'S';
        else filtros.terca = 'N';

        if (cores.Qua === 'green') filtros.quarta = 'S';
        else filtros.quarta = 'N';

        if (cores.Qui === 'green') filtros.quinta = 'S';
        else filtros.quinta = 'N';

        if (cores.Sex === 'green') filtros.sexta = 'S';
        else filtros.sexta = 'N';

        if (cores.Sab === 'green') filtros.sabado = 'S';
        else filtros.sabado = 'N';

        if (cores.Dom === 'green') filtros.domingo = 'S';
        else filtros.domingo = 'N';

        if (origemHorario === "HD") buscarHorarios(filtros)
        else buscarHorariosInscritos(filtros)

    }, [cores]);

    async function buscarHorarios(filtros) {
        if (dados.dominio) {
            setLoading(true);
            const pNomeUrl = dados.dominio.split(".")[0];

            await APISemNode.requisicaoGET(
                `https://${dados.dominio}`,
                `idProf=${dados.idProf}&token=${dados.token}&pNomeUrl=${pNomeUrl}&
            seg=${filtros.segunda}&ter=${filtros.terca}&quart=${filtros.quarta}&
            quint=${filtros.quinta}&sex=${filtros.sexta}&sab=${filtros.sabado}&dom=${filtros.domingo}`, 'md/mdLumen/lumen/HorarioProfissional/listarHorarioSegunda', dados.token).then(res => {
                    if (res?.codigo === 200) {
                        setValidado(true);
                        setRespostaRequisicao(res?.dados);
                    }
                }).catch(rej => {
                    setValidado(false);
                })

            setLoading(false);
        }
    }

    async function buscarHorariosInscritos(filtros) {

        setLoading(true);
        const pNomeUrl = dados.dominio.split(".")[0];

        await APISemNode.requisicaoGET(
            `https://${dados.dominio}`,
            `idProf=${dados.idProf}&token=${dados.token}&pNomeUrl=${pNomeUrl}&
            segunda=${filtros.segunda}&terca=${filtros.terca}&quarta=${filtros.quarta}&
            quinta=${filtros.quinta}&sexta=${filtros.sexta}&sabado=${filtros.sabado}&domingo=${filtros.domingo}`, 'md/mdLumen/lumen/HorarioProfissional/buscarConfigAtrelada', dados.token).then(res => {
                if (res?.codigo === 200) {
                    setValidado(true);
                    setRespostaRequisicao(res?.dados);
                }
            }).catch(rej => {
                setValidado(false);
            })

        setLoading(false);
    }

    return <div className="mt-3">
        <Stack direction={"row"} spacing={2} marginTop={2}>
            <Avatar sx={{ bgcolor: cores.Seg, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Seg')}>Seg</Avatar>
            <Avatar sx={{ bgcolor: cores.Ter, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Ter')} >Ter</Avatar>
            <Avatar sx={{ bgcolor: cores.Qua, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Qua')} >Qua</Avatar>
            <Avatar sx={{ bgcolor: cores.Qui, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Qui')} >Qui</Avatar>
            <Avatar sx={{ bgcolor: cores.Sex, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Sex')} >Sex</Avatar>
            <Avatar sx={{ bgcolor: cores.Sab, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Sab')} >Sab</Avatar>
            <Avatar sx={{ bgcolor: cores.Dom, width: avatarWidth, height: avatarHeight, fontSize: 10 }} onClick={() => handleFilter('Dom')} >Dom</Avatar>
        </Stack>

        {loading
            ? <div className="mt-4 text-center">
                <Spinner />
            </div>
            : validado
                ? <div style={{ maxHeight: "78vh", overflowY: "auto" }}>
                    {respostaRequisicao.map((horarios, i) => {
                        return <CardsHorarios key={horarios?.id} horario={horarios} cores={cores} origemHorario={origemHorario} />
                    })}
                </div>
                : <Alert severity="error" className="mx-3 mt-4 text-center">
                    Não possui horários ainda.
                </Alert>
        }
    </div>

}

