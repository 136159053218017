
import API from "./API.jsx"
import mensagemApp from './config/MensagemApp.js'
import packinfo from './../../package.json'

export default class ProfissionalAPI {
	static async logar(dados, email, senha) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			method: 'POST',
			metodo: 'POST',
			rota: '/login',
			data: {
				email: email,
				senha: senha,
				versao: (!!window.AndroidInterface ? window.AndroidInterface.recoverVersion() : 1),
				imei: (!!window.AndroidInterface ? window.AndroidInterface.recoverImei() : 'jgqijegqg'),
				idFirebase: (!!window.AndroidInterface ? window.AndroidInterface.recoverIdFirebase() : 'jgqijegqg')
			},
			headers: {
				"Content-Type": "application/json"
			},
		}).then(res => { return res.data }).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})
	}

	static async checarModulo(dados, sigla) {
		return await API.post("/appProfissional/acessar", {
			rota: "/checarModulo",
			dominio: dados.dominio,
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
				sigla: sigla
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async relatarAntifraude(dados, identificador) {
		return await API.post("/appProfissional/accessOutside", {
			dominio: dados.dominio,
			method: 'POST',
			metodo: 'POST',
			url: `https://${dados.dominio}/md/mdLumen/lumen/recebeAntifraude`,
			data: {
				idProf: dados.idProf,
				identificador: identificador
			},
			headers: {
				"Content-Type": "application/json"
			},
		}).then(res => res.data).catch(rej => rej)
	}

	static async salvarDadosAndroid(dados) {
		return await API.post("/appProfissional/salvarDadosAndroid", {
			dominio: dados.dominio,
			data: dados
		}).then(res => res.data).catch(rej => rej)
	}

	static async arquivosPosCadastro(dados, body) {
		return await API.post("/appProfissional/acessar", {
			rota: "/arquivosPosCadastro",
			dominio: dados.dominio,
			method: "POST",
			metodo: "POST",
			data: body,
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarStatus(dados) {
		return await API.post("/appProfissional/acessar", {
			rota: "/buscarStatus",
			dominio: dados.dominio,
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async alterarStatus(status, dados, la, lo) {
		return await API.post("/appProfissional/acessar", {
			rota: '/alterarStatus',
			dominio: dados.dominio,
			method: "PUT",
			metodo: "PUT",
			data: {
				token: dados.token,
				idProf: dados.idProf,
				status: status,
				la: la,
				lo: lo
			},
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async enviarLocalizacao(dados, data) {
		return await API.post("/appProfissional/acessar", {
			rota: "/localizacaoProf",
			dominio: dados.dominio,
			method: "POST",
			metodo: "POST",
			data: data,
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarSaldo(dados) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/buscarSaldo`,
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf,
			},
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async validarToken(dados) {
		let resposta = await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/validarToken`,
			params: {
				idProf: dados.idProf,
				token: dados.token
			},
			method: "GET",
			metodo: "GET",
			data: {}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})

		return resposta
	}

	static async cadastrarProf(dadosEnvio, idFirebase, versao, dados) {
		dadosEnvio.celular = dadosEnvio.celular.replace(/[^0-9]/ig, "")

		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: '/cadastrar',
			method: "POST",
			metodo: "POST",
			data: {
				...dadosEnvio,
				idFirebase: idFirebase,
				versao: versao
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})
	}

	static async posCadastro(dados) {
		let resposta = await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: `/posCadastro`,
			method: "GET",
			metodo: "GET",
			cabecalho: {
				"token": dados.token
			},
			params: {
				idProf: dados.idProf
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})

		return resposta
	}

	static async alterarFoto(dados, URL) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: `/alterarFotoPerfil`,
			method: "PUT",
			metodo: "PUT",
			cabecalho: {
				"token": dados.token
			},
			data: {
				token: dados.token,
				idProf: dados.idProf,
				link: URL
			},
		}).then(res => res.data).catch(rej => rej)
	}

	static async alterarFotoOld(dados, foto) {
		return await API.post("/appProfissional/acessar", {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: `/alterarFoto`,
			method: "POST",
			metodo: "POST",
			cabecalho: {
				"token": dados.token
			},
			data: {
				token: dados.token,
				idProf: dados.idProf,
				foto: foto
			},
		}).then(res => res.data).catch(rej => rej)
	}

	static async buscarAtivo(dados) {
		return await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: `/buscarAtivo?idProf=${dados.idProf}`,
			method: "GET",
			metodo: "GET",
			cabecalho: {
				token: dados.token
			}
		}).then(res => res.data).catch(rej => rej)
	}

	static async enviarCadastroPos(dados, dadosFormulario) {
		return await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: '/dadosAdicionais',
			method: "POST",
			metodo: "POST",
			data: dadosFormulario,
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => ({ codigo: 400, mensagem: mensagemApp.msgErroApi }))
	}

	static async buscarTermosUso(dados) {
		let resposta = await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: '/termosDeUso',
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				"token": dados.token
			},
			timeout: 2000
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})

		return resposta
	}

	static async buscarAvaliacao(dados) {
		let resposta = await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: '/buscarAvaliacao',
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				"token": dados.token
			},
			timeout: 2000
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})

		return resposta
	}

	static async alterarDados(dados, nome, email, celular, telefone) {

		let resposta = await API.post('/appProfissional/acessar', {
			dominio: dados.dominio ?? 'mototaxionline.srv.br',
			rota: '/alterarMeusDados',
			method: "PUT",
			metodo: "PUT",
			data: {
				idProf: dados.idProf,
				nome: nome,
				email: email,
				celular: celular,
				telefone: telefone
			},
			cabecalho: {
				"token": dados.token
			},
			timeout: 2000
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})

		return resposta
	}

	static async buscarDadosAdicionais(dados) {
		return await API.post('/appProfissional/acessar', {
			dominio: dados.dominio,
			rota: "/docPerfil",
			method: "GET",
			metodo: "GET",
			params: {
				idProf: dados.idProf
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return { codigo: 400, mensagem: mensagemApp.msgErroApi }
		})
	}

	static async enviarDadosUsuario(dados, campos) {
		return await API.post('/appProfissional/acessar', {
			dominio: dados.dominio,
			rota: "/dadosAtualizaveis",
			method: "PUT",
			metodo: "PUT",
			data: {
				idProf: dados.idProf,
				...campos
			},
			cabecalho: {
				token: dados.token
			}
		}).then(res => {
			return res.data
		}).catch(rej => {
			return rej
		})
	}

	static async buscarTemoDeUsoAceito(dados) {
		return API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: `/buscarTermoUso?idProf=${dados.idProf}`,
			method: "GET",
			metodo: "GET",
			data: {
				idProf: dados.idProf,
			},
			cabecalho: {
				"token": dados.token
			}
		}).then(res => res.data).catch(rej => ({ codigo: 400, mensagem: mensagemApp.msgErroApi }))
	}

	static salvarDadosAdicionais = async (dados, data) => API.post("/appProfissional/acessar", {
		dominio: dados.dominio,
		rota: "/atualizarDados",
		method: "PUT",
		metodo: "PUT",
		data: data,
		cabecalho: {
			"token": dados.token
		}
	}).then(res => res.data).catch(rej => null)

	static avaliacao = async (dados) => API.post("/appProfissional/acessar", {
		dominio: dados.dominio,
		rota: "/qualificarProfissional",
		method: "GET",
		metodo: "GET",
		params: {
			idProf: dados.idProf
		},
		cabecalho: {
			"token": dados.token
		}
	}).then(res => res.data).catch(rej => rej)
}