import "./login.module.css";

import API from "../../../servicos/API";

import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { Campo } from "../../../componentes/form/campo";
import { Spinner } from "../../../componentes/Spinner";

import packinfo from "../../../../package.json";

import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { configAnim } from "../../../servicos/config/configAnim";
import ProfissionalAPI from "../../../servicos/ProfissionalAPI";

import { Alert, Backdrop, Button } from "@mui/material";
import mensagemApp from "../../../servicos/config/MensagemApp.js";

import { MdClose } from "react-icons/md";
import TopoMenu from "../../../componentes/gerais/TopoMenu";

// OBTER O IMEI
export function Login() {
	document.title = "Aplicativo do Profissional"

	const [loading, setLoading] = useState(false)
	const [show, setShow] = useState([false, null])
	const [auth, setAuth] = useState(" ")
	const { dados, setDados } = useContext(ContextoGeral)
	const [msg, setMsg] = useState("")

	const [linkTermos, setLinkTermos] = useState("")
	const [logado, setLogado] = useState(false)
	const [statusLogado, setStatusLogado] = useState("")

	const [email, setEmail] = useState("")
	const [senha, setSenha] = useState("")

	const handleEmail = (event) => {
		setEmail(event.target.value);
	}

	const handleSenha = (event) => {
		setSenha(event.target.value);
	}

	async function termoDeUso() {
		let retorno = await ProfissionalAPI.buscarTermosUso(dados)

		if (retorno?.resultado) {
			if (retorno.dados != "" && retorno.dados.link != "") {
				setLinkTermos(retorno.dados.link);
			}
		}
	}

	async function definirToken(res) {
		let versionApp = window.AndroidInterface?.recoverVersion() ?? 100.39

		let jsonDados = {
			...dados,
			avaliacaoProf: res.dados.avaliacaoProf,
			idProf: res.dados.id,
			token: res.dados.token,
			nome: res.dados.nome,
			email: res.dados.email,
			status: res.dados.status,
			foto: res.dados.foto,
			statusOnline: false,
		}

		if (res.dados.status == "OL")
			jsonDados.statusOnline = true

		if (res.dados.codigo !== undefined)
			jsonDados.codigo = res.dados.codigo

		if (res.dados.tipoVeiculo !== undefined && res.dados.tipoVeiculo !== null)
			jsonDados.tipoVeiculo = res.dados.tipoVeiculo

		let tokenJWT = await API({
			url: "/gerarTokenJWT",
			method: "POST",
			data: JSON.stringify(jsonDados),
			headers: {
				"Content-Type": "application/json",
			},
			timeout: 2000,
		}).then(res => res.data.token).catch(rej => rej)

		if (tokenJWT !== "" && window.AndroidInterface)
			window.AndroidInterface.saveToken(tokenJWT)

		setDados(jsonDados)

		if (window.AndroidInterface)
			window.AndroidInterface.stopBackgroundLocation()

		if (!res.dados.termoUso || versionApp <= 100.39) {
			setLogado(true)
			setStatusLogado(res.dados.ativo)
		} else {
			try {
				if (window.AndroidInterface) {
					setAuth(<></>)
					window.AndroidInterface.showTermoApp(res.dados.id)
				}
			} catch (e) { }
		}
	}

	async function logar() {
		setLoading(true)
		setAuth(<div className="mt-3">
			<Spinner />
		</div>)

		let resposta = await ProfissionalAPI.logar(dados, email, senha)

		if (resposta.resultado) {
			if (window.AndroidInterface) {
				try {
					const ver = await window.AndroidInterface.versaoSdkAndroid()
					const info = JSON.parse(await window.AndroidInterface.retornaMarcaModelo())

					const resMD = await ProfissionalAPI.salvarDadosAndroid({
						dominio: dados.dominio,
						avaliacaoProf: resposta.dados.avaliacaoProf,
						idProf: resposta.dados.id,
						androidAPI: ver,
						marca: info.marca,
						modelo: info.modelo
					})

				} catch {
					console.error("Obter info. do aparelho não está disponível nesta versão")
				}
			}
			definirToken(resposta);
		} else {
			if (resposta.aux == "usuarioInativo") {
				definirToken(resposta);
			} else {
				if (resposta?.mensagem) {
					setAuth(
						<Alert severity="error" className="textCenter mt-3">
							{resposta.mensagem}
						</Alert>
					);
				} else {
					setAuth(
						<Alert severity="error" className="textCenter mt-3">
							{mensagemApp.msgErroApi}
						</Alert>
					);
				}
			}
		}

		setTimeout(() => setLoading(false), 800)
	}

	useEffect(() => {
		if (!!window.AndroidInterface) {
			let emailSalvo = window.AndroidInterface.recoverEmail()
			let senhaSalva = ""
			try {
				senhaSalva = window.AndroidInterface.recoverPassword()
			} catch {
				console.error("Não foi possível recuperar e-mail e senha neste aplicativo")
			}

			if (emailSalvo != "" || emailSalvo != undefined) {
				setEmail(emailSalvo)
			}

			if (senhaSalva != "" || senhaSalva != undefined) {
				setSenha(senhaSalva)
			}
		}

		termoDeUso()
	}, [])

	useEffect(() => {
		if (logado) {
			setAuth(() => {
				if (!!window.AndroidInterface) {
					window.AndroidInterface.saveEmail(email)
					try {
						window.AndroidInterface.savePassword(senha)
					} catch (e) {
						console.error("Não foi possível salvar e-mail e senha neste aplicativo")
					}
				}

				return (
					<>
						<Alert key={"success"} severity="success" className="textCenter mt-3">
							{mensagemApp.msgSucessoLogar}
							{statusLogado === "N" ? <Navigate to="/app/cadastro/pos" /> : <Navigate to="/app/home" />}
						</Alert>
						<div className="mt-3">
							<Spinner />
						</div>
					</>
				);
			});
		}
	}, [logado])

	return <>
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<>
				<div className="vh-100 d-flex flex-column justify-content-between">
					<div>
						<TopoMenu voltar="/home" titulo="LOGIN" />
						<div className="mx-3 d-flex flex-column justify-content-between">
							<h3 className="textCenter">Que bom ter você aqui!</h3>

							<form>
								<div className="mt-3">
									<Campo
										id="nome"
										label="E-mail ou nome de usuário"
										placeholder=" "
										tipo="text"
										autoComplete="email"
										value={email}
										onChange={handleEmail}
									/>
								</div>
								<div className="mt-3">
									<Campo
										id="senha"
										label="Senha"
										placeholder=" "
										tipo="password"
										value={senha}
										onChange={handleSenha}
									/>
								</div>
							</form>
							<div className="textRight mt1">
								<Link to="/recuperarSenha">Esqueci a senha</Link>
							</div>
							<div>{msg}</div>
							<motion.div
								key={auth}
								initial={{
									opacity: 0,
								}}
								animate={{
									opacity: 1,
								}}
								exit={{
									opacity: 0,
								}}
								transition={{ ease: "easeInOut", duration: 0.2 }}
							>
								{auth}
							</motion.div>
						</div>
					</div>
					<div className="w-100 d-flex flex-column mb-3 px-3">
						<div className="text-center mb-2 opacity-75 small">
							<div>
								Versão {!!window.AndroidInterface && window.AndroidInterface.recoverVersion()} •{" "}
								{packinfo.version}
							</div>
						</div>
						<Button variant="contained" onClick={logar} disabled={loading}>ENTRAR</Button>
						<div className="d-flex justify-content-between mt-3">
							<Button size="small" onClick={() => setShow([true, "privacidade"])}><small>Política de privacidade</small></Button>
							{linkTermos && (
								<Button size="small" onClick={() => setShow([true, "uso"])}><small>Termos de uso</small></Button>
							)}
						</div>
					</div>
				</div>
			</>
		</motion.div>

		<Backdrop open={show[0]} sx={{ zIndex: 2000 }}>
			<div style={{ height: "100vh", width: "100%", backgroundColor: "white", zIndex: 2000 }}>
				<div className="d-flex justify-content-end p-3">
					<MdClose size={32} onClick={() => setShow([false, null])} />
				</div>
				<iframe
					style={{ height: "calc(100% - 5rem)", width: "100%" }}
					src={show[1] == "uso"
						? `${linkTermos}?exibirBtn=false`
						: "https://" + dados.dominio + "/politicaPrivacidadeAndroid"}
					title="política de privacidade"
				/>
			</div>
		</Backdrop>
	</>
}
